body {
	margin: 0;
	background-color: #f8f8f8;
	overflow: auto;
}

.BrowseView {
	background-color: #f8f8f8;
}

.MuiFormControl-root, .MuiDialog-paperWidthSm {
	width: 100% !important;
}

.MuiFab-label {
	white-space: nowrap;
}

.MuiList-root .MuiTypography-root.MuiTypography-body2.MuiListItemText-primary {
	font-size: 0.9285714285714286rem;
}

.MuiDialogActions-root button.MuiButton-containedPrimary {
	padding: 10px 20px 10px 20px;
}

.MuiDialogActions-root button.MuiButton-textPrimary {
	padding: 10px;
}

.MuiDialogActions-root.MuiDialogActions-spacing {
	height: 48px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  --webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiExpansionPanelDetails-root {
	padding-top: 0 !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.MuiButtonBase-root.MuiExpansionPanelSummary-root {
	min-height: 48px !important;
	height: 48px !important;
}

.MuiExpansionPanelSummary-content .MuiTypography-root.MuiTypography-body1 {
	display: flex;
	align-items: center;
}

.MuiExpansionPanelSummary-content .MuiTypography-root.MuiTypography-body1 > svg, .MuiExpansionPanelSummary-content .MuiTypography-root.MuiTypography-body1 > img {
	margin-right: 5px;
	opacity: .8;
	height: 18px;
}

.MuiExpansionPanel-root.Mui-expanded:before {
	opacity: 1 !important;
}

.MuiExpansionPanel-root.Mui-expanded:after {
	opacity: 1 !important;
}

.MuiExpansionPanel-root.Mui-expanded + .MuiExpansionPanel-root:before {
	display: block !important;
}

.MuiDrawer-root * {
	scrollbar-width: thin;
}

.MuiDrawer-root ::-webkit-scrollbar {
    width: 6px;
}

.MuiDrawer-root ::-webkit-scrollbar-track {
	background-color: #eee;
}
 
.MuiDrawer-root ::-webkit-scrollbar-thumb {
	background-color: #bbb;
}

.sportsExpansionPanel {
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 0 !important;
}

.sportsExpansionPanel > .MuiList-root {
	width: 100%;
}

.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.facebook-btn {
	background-color: rgb(255, 255, 255); 
	display: inline-flex; 
	align-items: center; 
	color: rgba(0, 0, 0, 0.54); 
	box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px; 
	padding: 0px; 
	border-radius: 2px; 
	border: 1px solid transparent; 
	font-size: 14px; 
	font-weight: 500; 
	font-family: Roboto, sans-serif;
	cursor: pointer;
}

.facebook-btn .facebook-logo {
	margin-right: 10px; 
	background: rgb(255, 255, 255); 
	padding: 10px; 
	border-radius: 2px;
	max-width: 38px;
	box-sizing: border-box;
}

.facebook-btn .facebook-text {
	padding: 10px 10px 10px 0px; 
	font-weight: 500;
}

.MarkdownView h3:first-of-type {
	margin-top: 0;
}

.ReportView > .MuiPaper-root {
	padding: 0 !important;
	display: flex;
	flex-flow: column;
}

.ReportView {
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
}

.ReportView .MuiTable-root {
	max-width: 100%;
	flex: 1 1;
}

.ReportView th {
	font-weight: bold;
}

.ReportView .table-wrapper {
	overflow: auto;
	height: 100%;
}

.HtmlView img {
	max-width: 100%;
}

.MuiInputBase-multiline.MuiFilledInput-marginDense {
	padding-top: 2px !important;
	padding-left: 0 !important;
	padding-bottom: 0 !important;
	background: transparent !important;
}

.ArticlesView .MuiButtonBase-root.MuiCardActionArea-root {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.ArticlesView .MuiButtonBase-root.MuiCardActionArea-root > .MuiCardContent-root {
	width: 100%;
	box-sizing: border-box;
    display: flex;
    flex-flow: column;
    flex: 1 1;
}

.ArticlesView .MuiButtonBase-root.MuiCardActionArea-root > .MuiCardMedia-root {
	width: 100%;
}

.TeamView .MuiListItem-root {
	padding-right: 0;
}

.TeamView .MuiListItem-root > .MuiGrid-root {
	width: 100% !important;
}

.main {
    display: flex;
    flex-flow: column;
    flex: 1 1;
    width: 100%;
}

.BrowseView {
	display: flex;
	flex-flow: column;
    align-self: center;
	box-sizing: border-box;	
	width: 100%;
    max-width: 1960px;
}

.fb_dialog_content > iframe {
	left: 0px !important;
	bottom: 60px !important;
}
iframe.fb_customer_chat_bounce_in_v2 {
	left: -10px !important;
	bottom: 120px !important;
	max-height: calc(100% - 180px) !important;
}
iframe.fb_customer_chat_bounce_out_v2 {
	left: -10px !important;
	bottom: 120px !important;
}

@media only screen and (min-width: 960px) {
	.fb_dialog_content > iframe {
		left: 295px !important;
		bottom: 8px !important;
	}
	iframe.fb_customer_chat_bounce_in_v2 {
		left: 278px !important;
		bottom: 63px !important;
	}
	iframe.fb_customer_chat_bounce_out_v2 {
		left: 278px !important;
		bottom: 63px !important;
	}
}

@media only screen and (min-width: 1960px) {
	.fb_dialog_content > iframe {
		left: calc(50% - 980px + 295px) !important;
		bottom: 8px !important;
	}
	iframe.fb_customer_chat_bounce_in_v2 {
		left: calc(50% - 980px + 278px) !important;
		bottom: 63px !important;
	}
	iframe.fb_customer_chat_bounce_out_v2 {
		left: calc(50% - 980px + 278px) !important;
		bottom: 63px !important;
	}

	#LeftDrawer > div {
		left: calc(50% - 980px) !important;
		border-left: 1px solid rgba(0, 0, 0, 0.12)
	}
	#RightDrawer > div {
		right: calc(50% - 980px) !important;
		border-right: 1px solid rgba(0, 0, 0, 0.12)
	}
	#NewAdButton {
		right: calc(50% - 680px) !important;
	}
}

#fb-root {
	position: fixed !important;
}

#fb-root .fb_dialog.fb_dialog_advanced {
	z-index: 1 !important;
}

.offer-wrapper {
	width: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 10px;
}

.text-outline
{
    color: white;
    text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;  
}
